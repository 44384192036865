import React, { Component } from 'react';
import Layout from "../components/layout";
import Intro from "../components/intro";
import BlogItem from "../components/BlogItem";
import { Link } from "gatsby";
import BackToPage from "../images/back-to-page.png";
import Nav from '../components/nav';

class IndexPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            isCategoryLoaded: false,
            categoryContent: [],
            current_path: null,
            data: [],
            search_text: null,
            search_result_length: null
        }
    }

    async componentDidMount() {
        const id = this.props
        console.log("componentDidMount", JSON.stringify(id))
        console.log("componentDidMount params", JSON.stringify(id.params))
        let category_name = id.params["*"];
        this.getAllTeams(category_name);
        this.setState({ current_path: this.props.location })
    }

    componentWillReceiveProps(nextProps) {
        console.log("App componentWillReceiveProps nextProps", nextProps.params["*"])
        let category_name = nextProps.params["*"];
        this.getAllTeams(category_name);
        this.setState({ current_path: nextProps.location })

    }


    async getAllTeams(category_name) {
        fetch("https://tatva.com/backendapi/api/v1/cms/by-type/blog")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result.data,
                        dataList: result.data
                    });
                    this.filterData(category_name)
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    // filter records by search text
    excludeColumns = ["_id", "slug", "description", "short_description", "type", "meta_tag", "meta_description", "document_title", "publish_type", "status", "created_by", "publish_date", "thumbnail", "image", "resource_file", "additional_fields", "__v"];

    filterData = (value) => {
        console.log("filterData", value)
        const lowercasedValue = value.toLowerCase().trim();
        if (lowercasedValue === "") {
            // setData(dataList);
            this.setState({ items: this.state.dataList })
        }
        else {
            console.log("this state data filterData", this.state.items)
            const filteredData = this.state.items.filter(item => {
                return Object.keys(item)?.some(key =>
                    this.excludeColumns.includes(key) ? false : item[key]?.toString().toLowerCase().includes(lowercasedValue)
                );
            });
            // setData(filteredData);
            console.log("filteredData", filteredData)
            this.setState({ data: filteredData, search_text: value, search_result_length: filteredData.length })
        }
    }

    render() {
        const { isLoaded, data, search_text, search_result_length } = this.state;
        // search_text: value, search_result_length: filteredData.length
        return (
            <Layout>
                {isLoaded ?
                    <Nav
                        active="team"
                        pageTitle={'Team By ' + search_text}
                        title={''}
                        description={''}
                        keywords={''}
                    ></Nav>
                    : ''}

                <Intro search="true"
                        searchType="blog"
                         title="Our Blog" description="Our blog provides legal and commercial information, analysis and updates of national and regional relevance."> </Intro>

                <div className="main-container" id="team-details-page">
                    <div className="our-practices-image2">
                        <div className="container">
                            <div className="author-info">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <p><Link to={"/team-by-category/Founder & Managing Partner#middle_content"} className="author_link">  <img alt={''} title={''} className="icon" src={BackToPage} /> Search Results </Link></p>
                                        <p className="search_text">{search_result_length} search result for {search_text} </p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="container team-outer-wrapper">
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <div className="blog-container">
                                        {/* {isLoaded ?
                                            <ul>
                                                {data.map(item => (
                                                    <React.Fragment>
                                                        <TeamItem
                                                            key={item._id}
                                                            title={item.title}
                                                            slug={item.slug}
                                                            category={JSON.parse(item.additional_fields)}
                                                            profile={item.image}
                                                            ProfileLink={"/team-details/" + item.slug}
                                                        >
                                                        </TeamItem>
                                                    </React.Fragment>
                                                ))}
                                            </ul>
                                            : ''} */}

                                        {isLoaded ?
                                            <React.Fragment>
                                                {data.map(item => (
                                                    <div className="" key={item._id}>
                                                        <BlogItem
                                                            category_show="true"
                                                            category={item.category}
                                                            title={item.title}
                                                            author={item.created_by}
                                                            date={item.publish_date}
                                                            short_content={item.short_description}
                                                            slug={item.slug}
                                                            description={item.description}
                                                        >
                                                        </BlogItem>
                                                    </div>
                                                ))}
                                                
                                            </React.Fragment>
                                            : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Layout>
        )
    }
}

export default IndexPage;

